jQuery(document).ready(function($){
	//Add compact class
	document.mainMenu = $('body');

	$('.burger').on('click', function() {
		$('body').toggleClass('nav--open');
		$('html').toggleClass('no-overflow');
	    return $(this).toggleClass('open');
	});
});
