jQuery(document).ready(function($){

	// Init slick
	$('.slick-init').slick();

	$(window).on('load', function() {
		// Init mMasinory
		$('.masonry-grid').masonry({
			itemSelector: '.grid-item',
			fitWidth: true,
		});
	});

});
